export default class ShowHideSnackbarManager {
  constructor(element) {
    this.element = element;

    this.shownSnackbars = [];

    this.triggers = this.element.querySelectorAll(".js-trigger");
  }

  init() {
    this.getShownSnackbars(true);
    this.bindSnackbarStatus();

    this.bindClickTrigger();
  }

  getShownSnackbars(isInitialData) {
    if (!isInitialData) {
      this.shownSnackbars = [];
    }
    this.triggers.forEach(trigger => {
      if (trigger.dataset.className === "show") {
        this.shownSnackbars.push(trigger);
      }
    });

    if (this.shownSnackbars.length !== 0) {
      this.showRootSnackbar();
      this.showSnackbar(this.shownSnackbars);

      window.hideSnackbar = this
      setTimeout(function(){
        window.hideSnackbar.hideRootSnackbar();
      }, 3000);

    }
  }

  bindSnackbarStatus() {
    this.triggers.forEach(trigger => {
      if (trigger.dataset.status === "error") trigger.classList.add("error");
      if (trigger.dataset.status === "warning")
        trigger.classList.add("warning");
      if (trigger.dataset.status === "success")
        trigger.classList.add("success");
    });
  }

  bindClickTrigger() {
    this.triggers.forEach(trigger => {
      trigger.addEventListener("click", this.handleClickTrigger.bind(this));
    });
  }

  handleClickTrigger(e) {
    let triggeredSnackbar = this.getTriggeredSnackbar(e.srcElement);

    this.hideSnackbar(triggeredSnackbar);
  }

  getTriggeredSnackbar(srcElement) {
    let triggeredSnackbar = srcElement;
    while (!triggeredSnackbar.classList.contains("js-trigger")) {
      triggeredSnackbar = triggeredSnackbar.parentElement;
    }

    return triggeredSnackbar;
  }

  showRootSnackbar() {
    if (!this.element.classList.contains("active")) {
      this.element.classList.add("active");
      return;
    }
  }

  hideRootSnackbar() {
    if (this.element.classList.contains("active")) {
      this.element.classList.remove("active");
      return;
    }
  }

  showSnackbar(shownSnackbars) {
    shownSnackbars.forEach(item => {
      item.classList.add("show");
    });
  }

  hideSnackbar(triggeredSnackbar) {
    triggeredSnackbar.classList.remove("show");
    triggeredSnackbar.dataset.className = "hide";
    this.getShownSnackbars(false);
    if (this.shownSnackbars.length === 0) {
      this.hideRootSnackbar();
    }
  }
}
