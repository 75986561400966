export default class Util {
  static getFullHeight(element){
    const style = window.getComputedStyle(element);

    const marginTop = parseInt(style.marginTop);
    const marginBottom = parseInt(style.marginBottom);

    return element.offsetHeight + marginTop + marginBottom;
  }
}
