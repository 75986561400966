// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import Fontawesome from './lib/fontawesome';


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Util from './lib/util';
window.Util = Util;

import ShowHideSnackbarManager from './components/show_hide_snackbar_manager';
window.ShowHideSnackbarManager = ShowHideSnackbarManager;

import Navbar from './components/navbar';
window.Navbar = Navbar;

import PageManager from './page_manager';
window.PageManager = PageManager;

window.pageManager = new window.PageManager(document);
window.pageManager.init();
