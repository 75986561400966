export default class PageManager {
  constructor(element){
    this.element = element;
  }

  init() {
    this.element.addEventListener('turbolinks:load', () => {
      // window.TeardownEventListenerRegistrar.performTeardown();
      window.PageManager.initialize(this.element);
    });
  }

  static initialize(element) {
    let components = Array.prototype.slice.call(
      element.querySelectorAll('.js-component:not(.js-initialized)')
    );

    for (let i = 0; i < components.length; i++) {
      let component = components[i];
      window.PageManager.initializeVolatile(component);
    }
  }

  static initializeVolatile(component) {
    let componentClasses = component.dataset.component.split(',');

    for(let i=0; i<componentClasses.length; i++){
      let volatileComponent = new window[componentClasses[i]](component);
      volatileComponent.init();
    }

    // component.classList.add('js-initialized');
  }
}
