export default class Navbar {
  constructor(element){
    this.element = element;
    this.navbar = document.getElementById('js-navbar');
    this.children = this.navbar.children;
  }

  init(){
    this.initChildrenHeight();
    this.bindClick();
  }

  initChildrenHeight(){
    let height = 0;
    for(let i=0; i<this.children.length; i++){
      height +=  window.Util.getFullHeight(this.children[i]);
    }
    this.childrenHeight = height;
  }

  bindClick(){
    this.element.addEventListener('click', this.handleClick.bind(this));
  }

  handleClick(){
    if(this.navbar.classList.contains('expand')){
      this.navbar.style.height = '0px';
      this.navbar.classList.remove('expand');
      return;
    }

    this.navbar.style.height = `${this.childrenHeight}px`;
    this.navbar.classList.add('expand');
  }
}
