import { config, library, dom } from '@fortawesome/fontawesome-svg-core';

// Change the config to fix the flicker
config.mutateApproach = 'sync';

// An example icon
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
  faLine,
  faWhatsappSquare,
} from '@fortawesome/free-brands-svg-icons';

import {
  faBars,
  faSearch,
  faEnvelope,
  faCheck,
  faAmbulance,
  faDollarSign,
  faTachometerAlt,
  faAngleLeft,
  faTh,
  faEye,
  faPencilAlt,
  faNewspaper,
  faStar,
  faThumbtack,
  faFolder,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCircle,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
  faBars,
  faSearch,
  faEnvelope,
  faCheck,
  faAmbulance,
  faDollarSign,
  faTachometerAlt,
  faAngleLeft,
  faTh,
  faPencilAlt,
  faNewspaper,
  faStar,
  faThumbtack,
  faFolder,
  faPlus,
  faLine,
  faWhatsappSquare,
);
dom.watch();

